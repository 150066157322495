import { Auth, Capture as CleengCapture } from '@cleeng/mediastore-sdk';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { STEPS, useActiveStep } from './Steps';
import { useTranslation } from 'react-i18next';

const Capture = () => {

    useActiveStep(STEPS.ACCOUNT);

    const navigate = useNavigate();
    const { t } = useTranslation();

    // Define functions to navigate away once capture is complete
    const [isCaptureComplete, setIsCaptureComplete] = useState(false);
    const onCaptureComplete = () => setIsCaptureComplete(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { isCaptureComplete && navigate('/offer-list'); }, [isCaptureComplete]);

    return (
        !Auth.isLogged() ? (
            <Navigate to="/" />
        ) : (
            <div>
                <h2 className="text-4xl text-white text-center pt-8 font-bold">{t('create-account')}</h2>
                <h3 className="text-center text-white py-3" dangerouslySetInnerHTML={{ __html: t("Price-info") }}></h3>
                <h4 className="text-3xl text-center py-5" dangerouslySetInnerHTML={{ __html: t("Promo") }}></h4>
                <CleengCapture onSuccess={onCaptureComplete} />
            </div>
        )
    );
}

console.log("Capture component rendered"); // 追加


export default Capture;