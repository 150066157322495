import { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from './translations';

export const STEPS = Object.freeze({
    ACCOUNT: 1,
    PLAN: 2,
    PAYMENT: 3,
    COMPLETED: 4
});

export const StepsContext = createContext(0);

export const useActiveStep = (step) => {

    /*
     * This kind of sucks since it requires the state set function
     * to be passed in the context provider.
     */
    const { setActiveStep } = useContext(StepsContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setActiveStep(step), []);
};

const CheckIcon = () => <svg width="24px" height="24px" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#ffffff"><path d="M5 13L9 17L19 7" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>;

const Steps = () => {
    const { activeStep } = useContext(StepsContext);
    const { t } = useTranslation('jme', { i18n });

    return (
        <div
            className="relative"
        >
            <ol className="z-10 flex justify-between text-sm font-medium text-white">
                <li className="flex flex-col items-center gap-2 p-2 w-[30%]">
                <span className={`box-content size-10 leading-10 
                ${activeStep === STEPS.ACCOUNT ? 'bg-white text-black' : 'bg-[#181818]'} 
                rounded-full border border-white text-lg flex items-center justify-center`}>
                {activeStep > STEPS.ACCOUNT ? <CheckIcon /> : 1}
                </span>
                    <span className="block text-center">{t('create-account')}</span>
                </li>

                <li className="h-0.5 bg-white grow mt-7"></li>

                <li className="flex flex-col items-center gap-2 p-2 w-[30%]">
                    <span className={`box-content size-10 leading-10 
                        ${activeStep === STEPS.PLAN ? 'bg-white text-black' : 'bg-[#181818]'} 
                        rounded-full border border-white text-lg flex items-center justify-center`}>
                        {activeStep > STEPS.PLAN ? <CheckIcon /> : 2}
                    </span>
                    <span className="block text-center">{t('select-your-plan')}</span>
                </li>

                <li className="h-0.5 bg-white grow mt-7"></li>

                <li className="flex flex-col items-center gap-2 p-2 w-[30%]">
                    <span className={`box-content size-10 leading-10 
                        ${activeStep === STEPS.PAYMENT ? 'bg-white text-black' : 'bg-[#181818]'} 
                        rounded-full border border-white text-lg flex items-center justify-center`}>
                        {activeStep > STEPS.PAYMENT ? <CheckIcon /> : 3}
                    </span>
                    <span className="block text-center">{t('setup-your-payment')}</span>
                </li>
            </ol>
        </div>
    )
};

export default Steps;
