import React, { useState, useEffect } from 'react';
import { Auth, Register } from '@cleeng/mediastore-sdk';
import { Navigate, useNavigate } from 'react-router-dom';
import { STEPS, useActiveStep } from './Steps';
import { useTranslation } from 'react-i18next';

const Registration = () => {
    useActiveStep(STEPS.ACCOUNT);
    const navigate = useNavigate();
    const { t } = useTranslation ();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            const emailInput = document.querySelector('input[type="email"]');
            
            if (!emailInput) return;

            const validateEmail = (email) => {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const commonMistakes = [
                    '.con', '.com', '.cmo', '.vom',
                    '@gmai.com', '@gmial.com', '@gamil.com',
                    '@hotmai.com', '@hotmail.cmo'
                ];
                const hasMistake = commonMistakes.some((mistake) => email.includes(mistake));

                if (!emailRegex.test(email)) {
                    return t('email-validation.invalid', 'Please enter a valid email address');
                }
                if (hasMistake) {
                    return t('email-validation.typo', 'Please double-check your email address');
                }
                return '';
            };

            const handleBlur = () => {
                const emailValue = emailInput.value.trim();
                const warning = validateEmail(emailValue);
                if (warning) {
                    setPopupMessage(warning);
                    setShowPopup(true);
                } else {
                    setShowPopup(false);
                }
            };

            emailInput.addEventListener('blur', handleBlur);

            return () => {
                emailInput.removeEventListener('blur', handleBlur);
            };
        }, 1000);

        return () => clearTimeout(timer);
    }, [t]);

    return (
        Auth.isLogged() ? (
            <Navigate to={'/details'} />
        ) : (
            <div>
                <h2 className="text-4xl text-white text-center pt-8 font-bold">{t('create-account')}</h2>
                <h3 className="text-center text-white py-3" dangerouslySetInnerHTML={{ __html: t("Price-info") }}></h3>
            <h4 className="text-3xl text-center py-5" dangerouslySetInnerHTML={{ __html: t("Promo") }}></h4>

                
                <Register
                    onSuccess={() => navigate('/details')}
                    onHaveAccountClick={() => window.location = 'https://www.jme.tv'}
                />

                {/* Pop-up Modal */}
                {showPopup && (
                    <div
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white',
                            padding: '20px',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                            zIndex: '1000',
                            borderRadius: '8px',
                            textAlign: 'center'
                        }}
                    >
                        <h3 style={{ margin: 0, marginBottom: '10px', color: '#C84376' }}>
                            Email Confirmation
                        </h3>
                        <p style={{ margin: 0, marginBottom: '20px' }}>{popupMessage}</p>
                        <button
                            onClick={() => setShowPopup(false)}
                            style={{
                                backgroundColor: '#C84376',
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Okay
                        </button>
                    </div>
                )}
                {/* Background Overlay */}
                {showPopup && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: '999'
                        }}
                        onClick={() => setShowPopup(false)}
                    />
                )}
            </div>
        )
    );
};

export default Registration;
