import { useState, useEffect } from "react";
import Offer from "./Offer";
import { Navigate, useNavigate } from "react-router-dom";
import { STEPS, useActiveStep } from "./Steps";
import { Auth } from "@cleeng/mediastore-sdk";
import { useTranslation } from "react-i18next";
import i18n from './translations';


const offers = [
    {
        id: 'S342460575',
        title: 'Monthly',
        price: 25,
        period: 'month',
        subtext1: "subtext_Monthly",
        subtext: `<p>
        5日間無料でお試し！<br/>
        5日後に視聴料が課金されます<br/>
        5-DAY FREE TRIAL<br/>
        Subscription will be charged after 5 days</p>`,
        customText: '+TAX'
    },
    {
        id: 'S953087857',
        title: 'Annual',
        price: 250,
        period: 'year',
        subtext1: "subtext_Annual",
        subtext: `<p>5日間無料でお試し！<br/>
        5日後に視聴料が課金されます<br/>
        5-DAY FREE TRIAL<br/>
        Subscription will be charged after 5 days</p>`,
        customText: '+TAX',
        recommended: true
    }
];

/* Poor mans enum :( */
const LoadingStates = Object.freeze({
    LOADING: 1,
    READY: 2,
    ERROR: 3,
});

const OfferList = () => {
    useActiveStep(STEPS.PLAN);
    const { t } = useTranslation('jme', { i18n });

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [loadingState, setLoadingState] = useState(LoadingStates.LOADING);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffers = async () => {

            const user = localStorage.getItem('CLEENG_CUSTOMER_EMAIL');
            const requests = offers.map(offer => fetch(`https://mediastoreapi.cleeng.com/offers/${offer.id}/customers/${user}`));

            try {
                const responses = await Promise.all(requests);
                const data = await Promise.all(responses.map(response => response.json()));

                for (let offer of offers) {
                    let remoteOffer = data.find(o => o.responseData.offerId.includes(offer.id));
                    if (remoteOffer) {
                        offer.title = remoteOffer.responseData.offerTitle;
                        offer.price = remoteOffer.responseData.customerPriceInclTax;
                        offer.period = remoteOffer.responseData.period;
                        offer.currency = remoteOffer.responseData.customerCurrencySymbol;
                    }
                }

                setLoadingState(LoadingStates.READY);
            } catch {
                setLoadingState(LoadingStates.ERROR);
            }
        };

        fetchOffers().catch(() => setLoadingState(LoadingStates.ERROR));
    }, []);

    const onContinue = () => {
        if (selectedOffer === null) {
            return;
        }

        navigate(`/purchase/${selectedOffer}`);
    }

    if (!Auth.isLogged()) {
        return <Navigate to="/" />;
    }

    if (loadingState === LoadingStates.LOADING) {
        return;
    }

    if (loadingState === LoadingStates.ERROR) {
        return (
            <div className="px-16">
                <h2 className="text-4xl text-white text-center py-10">Error, please refresh</h2>
            </div>
        );
    }

    return (
        <div className="px-16">
            <div><h2 className="text-4xl text-white text-center pt-8 font-bold">{t('select-your-plan')}</h2></div>
            <div><h3 className="text-center text-white py-3" dangerouslySetInnerHTML={{ __html: t("Price-info") }}></h3></div>
            <div><h4 className="text-3xl text-center py-5" dangerouslySetInnerHTML={{ __html: t("Promo") }}></h4></div>


            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {offers.map(({ id, title, currency, price, period, subtext1, subtext, customText, recommended }) => (
                    <Offer
                        key={id}
                        title={t(title)}
                        currency={currency}
                        price={price}
                        period={t(period)}
                        subtext1={t(subtext1)}
                        subtext={subtext}
                        customText={customText}
                        recommended={recommended}
                        selected={selectedOffer === id}
                        onClick={() => setSelectedOffer(id)}
                    />
                ))}
            </div>

            <div className="text-xs text-white text-opacity-75 text-center py-5">
                <p>{t('最終的な金額はクレジットカード会社の換算レートに依存します。')}</p>
                <p>{t('Final charges depend on the exchange rate applied by your credit card provider.')}</p>
            </div>

            <button
                onClick={onContinue}
                className="btn-primary"
            >
                {t('continue')}
            </button>

            {/* <div className="text-xs text-white text-center py-6 opacity-70">
                <p>Please note that all prices exclude taxes.</p>
                <p>No Contract. Cancel Anytime.</p>
            </div> */}
        </div>
    );
}

export default OfferList;
